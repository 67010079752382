import axios from 'axios'
import { Notify } from 'vant'
import storage from 'store'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL_PHP,
  timeout: 20000, // request timeout
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

service.interceptors.request.use(config => {
  const token = storage.get('token') || ''
  if (token) {
    config.headers['access-token'] = token
  }
  if (config.method === 'post') {
    config.data = JSON.stringify(config.data)
  }
  return config
},
error => {
  return Promise.reject(error)
}
)

service.interceptors.response.use(response => {
  const res = response.data
  if (res.code !== 0) {
    if (res.code == 1058) {
      Notify({ type: 'warning', message: '请重新登录' || '接口报错' })
      return Promise.reject('Logout')
    } else {
      Notify({ type: 'warning', message: res.msg || '接口报错' })
    }
    return Promise.reject(res)
  } else {
    return res
  }
},
error => {
  console.log('err' + error) // for debug
  return Promise.reject(error)
}
)

export default service
