import Vue from 'vue'
import VueRouter from 'vue-router'
// import QuestionnaireDetails from '@/views/questionnaireDetails'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/index/index')
  },
  {
    path: '/identity', // 选择权益
    name: 'identity',
    component: () => import('@/views/chooseIdentity/index')
  },
  {
    path: '/role', // 选择角色
    name: 'role',
    component: () => import('@/views/chooseRole/index')
  },
  {
    path: '/login', // 家长登录
    name: 'login',
    component: () => import('@/views/login/login')
  },
  {
    path: '/submitInfo', // 未开通会员提交信息
    name: 'submitInfo',
    component: () => import('@/views/submitInfo/index')
  },
  {
    path: '/submitSuccess', // 提交成功
    name: 'submitSuccess',
    component: () => import('@/views/submitSuccess/index')
  },
  {
    path: '/readEvaluationRules', // 家长、儿童测评规则
    name: 'ReadEvaluationRules',
    component: () => import('@/views/readEvaluationRules/index')
  },
  {
    path: '/questionnaireDetails', // 问卷详情
    name: 'QuestionnaireDetails',
    // component: QuestionnaireDetails
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/questionnaireDetails/index'
      )
  },
  {
    path: '/parentInformation', // 家长信息
    name: 'ParentInformation',
    component: () => import('@/views/parentInformation')
  },
  {
    path: '/literacy', // 识字测评
    name: 'literacy',
    component: () => import('@/views/literacy/index')
  },
  {
    path: '/literacyDetail', // 识字详情
    name: 'literacyDetail',
    component: () => import('@/views/literacy/literacyDetail')
  },
  {
    path: '/result', // 测评结果
    name: 'result',
    component: () => import('@/views/literacy/result')
  },
  {
    path: '/complete', // 完成测评
    name: 'Complete',
    component: () => import('@/views/complete')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
