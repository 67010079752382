//计算周岁年龄
export function getAge(strBirthday) { //传入形式yyyy-MM-dd
    //strBirthday = util.formatTime(strBirthday);转换成yyyy-MM-dd形式
    var returnAge
    var strBirthdayArr = strBirthday.split('-')
    var birthYear = strBirthdayArr[0]
    var birthMonth = strBirthdayArr[1]
    var birthDay = strBirthdayArr[2]
    var d = new Date()
    var nowYear = d.getFullYear()
    var nowMonth = d.getMonth() + 1
    var nowDay = d.getDate()
    if (nowYear == birthYear) {
        returnAge = 0 //同年 则为0岁
    } else {
        var ageDiff = nowYear - birthYear //年之差
        if (ageDiff > 0) {
            if (nowMonth == birthMonth) {
                var dayDiff = nowDay - birthDay //日之差
                if (dayDiff < 0) {
                    returnAge = ageDiff - 1
                } else {
                    returnAge = ageDiff
                }
            } else {
                var monthDiff = nowMonth - birthMonth //月之差
                if (monthDiff < 0) {
                    returnAge = ageDiff - 1
                } else {
                    returnAge = ageDiff
                }
            }
        } else {
            returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
        }
    }
    return returnAge //返回周岁年龄
}

//格式或计时器 00:00:00
export function formatSeconds(value) {
    let secondTime = parseInt(value);
    let minuteTime = 0;
    let hourTime = 0;
    if (secondTime >= 60) {
        minuteTime = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (minuteTime >= 60) {
            hourTime = parseInt(minuteTime / 60);
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    // 补0
    hourTime = hourTime < 10 ? "0" + hourTime : hourTime;
    minuteTime = minuteTime < 10 ? "0" + minuteTime : minuteTime;
    secondTime = secondTime < 10 ? "0" + secondTime : secondTime;
    let res = hourTime + ":" + minuteTime + ":" + secondTime;
    return res;
}

export function timestampToTime(date) {
    // 时间戳为10位需*1000，时间戳为13位不需乘1000
    var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var m =(date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())+ ":";
    var s =(date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds())
    return h + m + s;
  }