import Vue from 'vue'
import {
  Button,
  Form,
  Field,
  Popup,
  Picker,
  Calendar,
  DatetimePicker,
  Toast,
  Notify,
  Progress,
  Tag,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Loading
} from 'vant'
Vue.use(Button)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Calendar)
Vue.use(DatetimePicker)
Vue.use(Form)
Vue.use(Toast)
Vue.use(Notify)
Vue.use(Progress)
Vue.use(Tag)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Loading)
