<template>
  <div>
    <div class="head">
      <img :src="isBack ? header2 : header1" alt="" />
      <img
        :src="header_back"
        alt=""
        v-if="isBack"
        class="back"
        @click="goBack"
      />
      <div class="bottom_blank" v-if="isShow">
        <div class="loading" v-if="showLoading">
          <van-loading type="spinner" size="20" v-if="loading"/>
          <span v-else>已保存({{saveTime}})</span>
        </div>
        <header>
          <div class="left">
            <span></span>
          </div>
          <div class="center">
            <img src="@/assets/images/questionnaire.png" alt="" />
            {{ headerText }}
          </div>
          <div class="right">
            <span></span>
          </div>
        </header>
        <div class="time">
          <span>已用时</span>
          <span class="timer">{{ count | timeFilter }}</span>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import header1 from '@/assets/images/header_1.png'
import header2 from '@/assets/images/header_2.png'
import header_back from '@/assets/images/header_back.png'
import { formatSeconds } from '@/utils/public'
let timer = null
let startTime = 0
let endTime = 0
let stopTime = 0

export default {
  props: {
    isBack: {
      // 是否包含返回
      type: Boolean,
      default: false,
    },
    isShow: {
      // 是否显示问卷title
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: '家长问卷',
    },
    test_starttime:{
      type: String,
      default: '',
    },
    loading:{
      type:Boolean,
      default:false
    },
    showLoading:{
      type:Boolean,
      default:false
    },
    saveTime:{
      type:String,
      default:""
    }
  },
  name: '',
  data() {
    return {
      header1,
      header2,
      header_back,
      count: 0,
      test: '',
    }
  },
  created() {
    // this.count =100
    // console.log(this.stopTime);
    document.addEventListener('visibilitychange', this.resetTime)
  },
  beforeDestroy() {
    //   window.removeEventListener('popstate', this.goBack)
    if (this.count > 0) {
      window.removeEventListener('visibilitychange', this.resetTime)
    }
  },
  filters: {
    timeFilter(value) {
      const number = parseInt(value) || 0
      return formatSeconds(number)
    },
  }, 
  methods: {
    setCount(time){
      console.log(time);
      let date = +new Date(),endDate =+new Date(time)
      this.count = Math.floor((date - endDate)/1000)
    },
    resetTime() {
      if (document.visibilityState == 'hidden') {
        startTime = new Date().getTime()
        stopTime = this.count
        // this.count = 0
      } else if (document.visibilityState == 'visible') {
        endTime = new Date().getTime()
        this.count = stopTime + Math.round((endTime - startTime) / 1000)
      }
    },
    goBack() {
      this.$emit('goBack')
    },
    // 开始计时
    startHandler() {
      timer = setInterval(() => {
        this.count++
      }, 1000)
    },
  },
  destroyed() {
    clearInterval(timer)
  },
}
</script>
<style scoped>
.head {
  position: relative;
  width: 100%;
}

.head::before {
  content: '';
  width: 100%;
  position: absolute;
  background-color: #fff;
  height: 32px;
  bottom: -1px;
  left: 0;
  border-radius: 32px 32px 0 0;
}

.head img {
  vertical-align: top;
  width: 100%;
}

img.back {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: absolute;
  left: 30px;
  top: 24px;
}
</style>
<style lang="less" scoped>
.bottom_blank {
  position: relative;
  display: flex;
  // justify-content: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
  height: 64px;
  bottom: 28px;
  left: 0;
  border-radius: 32px 32px 0 0;
  .loading{
    position: absolute;
    left: 20px;
    top:16px;
    font-size: 24px;
    color: #999;
    z-index: 999;
  }
  header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 32px 32px;
    height: 64px;
    padding: 0px 16px;
    background-color: #e3fce7;
    font-size: 28px;
    font-weight: 700;
    color: #4ea259;

    .left {
      position: absolute;
      top: 0px;
      left: -32px;
      width: 32px;
      height: 32px;
      background-color: #e3fce7;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 0px 60px 0px 0px;
        background-color: #ffffff;
      }
    }

    .right {
      position: absolute;
      top: 0px;
      right: -32px;
      width: 32px;
      height: 32px;
      background-color: #e3fce7;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 60px 0px 0px 0px;
        background-color: #ffffff;
      }
    }

    img {
      width: 40px;
      height: 40px;
      margin-right: 6px;
    }
  }

  .time {
    position: relative;
    right: -78px;
    display: flex;
    align-items: center;

    span:first-child {
      position: absolute;
      right: -4px;
      width: 70px;
      font-size: 22px;
      font-weight: 700;
      color: #94999c;
    }

    .timer {
      position: absolute;
      display: flex;
      margin-left: 4px;
      font-size: 22px;
      font-weight: 700;
      color: #cdb022;
    }
  }
}
</style>
