import Vue from 'vue'
import Vuex from 'vuex'
import { startCP } from "@/api/api"
import storage from 'store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "",
    terminal_id: "", //门店id
    testInfo: "", //试卷信息
  },
  getters: {
  },
  mutations: {
    SET_TOKEN: (state, data) => {
      state.token = data
    },
    CLEAR_TOKEN: (state) => {
      state.token = ""
    },
    SET_TESTINFO: (state, data) => {
      state.testInfo = data
    }
  },
  actions: {
    setToken({ commit }, token) {
      if (storage.get('token')) {
        storage.remove('token')
      }
      storage.set('token', token)
      commit('SET_TOKEN', token)
    },
    clearToken({ commit }) {
      storage.remove('token')
      storage.remove('testInfo')
      commit('CLEAR_TOKEN')
    },
    saveTestpaper({ commit }, params) {
      return new Promise((resolve, reject) => {
        startCP(params).then(res => {
          if (storage.get('testInfo')) {
            storage.remove('testInfo')
          }
          storage.set('testInfo', { ...res.data, ...params })
          commit('SET_TESTINFO', { ...res.data, ...params })
          resolve()
        }).catch(err => {
          console.log(err);
        })
      })
    }
  },
  modules: {
  }
})
