// JAVA api
import service from '@/utils/request'
// PHP api
import servicePhp from '@/utils/requestPhp'

// H5获取手机号下的用户信息
export function getMemberInfo(parameter) {
  return service({
    url: '/bookstore/measurementApi/h5/getMemberInfo',
    method: 'POST',
    data: parameter
  })
}

// 测评发送验证码
export function measurementBizSms(parameter) {
  return service({
    url: '/push/measurementBizSms',
    method: 'POST',
    data: parameter
  })
}
// H5获取年级字典数据
export function gradeDictData(parameter) {
  return service({
    url: '/bookstore/measurementApi/h5/gradeDictData',
    method: 'GET',
    params: parameter
  })
}
// 插入测评用户
export function saveMemberInfo(parameter) {
  return service({
    url: '/bookstore/measurementApi/h5/saveMemberInfo',
    method: 'POST',
    data: parameter
  })
}
// H5获取测评等级字典数据
// export function levelDictData(parameter) {
//   return service({
//     url: '/bookstore/measurementApi/h5/levelDictData',
//     method: 'GET',
//     params: parameter
//   })
// }

// 插入测评用户
export function startCP(parameter) {
  return servicePhp({
    url: '/student/testpaper/start',
    method: 'post',
    params: parameter
  })
}

// 试卷详情
export function getDetailsApi(parameter) {
  return servicePhp({
    url: '/student/testpaper/details',
    method: 'post',
    data: parameter
  })
}

// 获取开始时间
export function getStartTime(parameter) {
  return servicePhp({
    url: '/student/testpaper/time',
    method: 'get',
    params: parameter
  })
}

// 家长填写信息-获取基本信息
export function essentialInformationApi(parameter) {
  return servicePhp({
    url: '/admin/common/data',
    method: 'get',
    params: parameter
  })
}

// 家长填写完成-提交信息
export function parentSubmitApi(parameter) {
  return servicePhp({
    url: '/student/testpaper/parentdata',
    method: 'post',
    params: parameter
  })
}

// 提交答题
export function submitForm(parameter) {
  return servicePhp({
    url: '/student/testpaper/submit',
    method: 'post',
    data: parameter
  })
}

// 提交试卷
export function submitApi(parameter) {
  return servicePhp({
    url: '/student/testpaper/submit',
    method: 'post',
    data: parameter
  })
}

// 识字评测结果
export function literacyResult(parameter) {
  return servicePhp({
    url: '/student/testpaper/result',
    method: 'get',
    params: parameter
  })
}


// 测评阶段
export function levelDictData(parameter) {
  return servicePhp({
    url: '/common/data',
    method: 'get',
    params: parameter
  })
}
